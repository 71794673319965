import React from "react";
import DummyImg from "assets/394.png";
import Picture from "assets/ads-assets/imgone.webp";

const FifthAdComp = ({ data }) => {
  return (
    <div className="bg-gradient-to-b from-[#E8F1F5] to-white py-[8rem]">
      <div className="flex flex-col w-full px-4 sm:w-9/12 2xl:w-7/12 mx-auto">
        <h2 className="text-[40px]  font-secondary text-center text-[#00173A] sm:w-[696px] mx-auto">
          Choosing our Website Translation Services Can:
        </h2>

        <div className="flex sm:flex-row flex-col gap-x-6 justify-center sm:justify-between pt-[4rem]">
          {data
            ? data.map(({ index, head, content, picture, head2 }) => (
                <div className="sm:pt-0 pt-6" key={index}>
                  <img src={picture} alt="" className=" pb-2 sm:m-0 mx-auto" />
                  <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary text-center sm:text-left">
                    {head} <br /> {head2}
                  </h3>
                  <p className="text-[15px] font-sans text-[#00173A] leading-loose pt-8 sm:w-[239px] text-center sm:text-left">
                    {content}
                  </p>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default FifthAdComp;
