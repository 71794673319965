import React from "react";

const ThireAdComp = ({ handleTop }) => {
  return (
    <div className="py-[5rem] w-full  mx-auto bg-banner2-ads bg-no-repeat bg-cover">
      <div className="flex flex-wrap items-center sm:w-10/12 2xl:w-7/12 justify-center sm:justify-between mx-auto">
        <div className="">
          <h2 className="text-[#00173A] text-center sm:text-left font-semibold font-secondary text-[28px] sm:text-[40px]">
            {/* Our Website Translation services help all website owners achieve their global objectives. */}
            Our <span className="text-orange">Website</span> Translation <br />
            services help all website owners <br /> achieve their global
            objectives.
          </h2>
          <p className="text-[16px] sm:text-[18px] text-[#00173A] text-center sm:text-left leading-relaxed pt-8 sm:w-[579px]">
            We provide scalable and guaranteed website solutions that are
            delivered on time by our team of experts.
          </p>
        </div>
        <div className="pt-[3rem]">
          <button
            onClickCapture={handleTop}
            href=""
            className="bg-orange hover:bg-[#FF9848] font-sans text-[18px] text-white font-bold"
            style="box-shadow: 0px 3px 17px #00000059; width: 318px;
            height: 81px; border-radius: 40px;"
          >
            INQUIRE NOW
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThireAdComp;
