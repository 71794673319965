import React from "react";
import Picture from "assets/ads-assets/img2.webp";

const NewCompOne = () => {
  return (
    <div className=" w-11/12 2xl:w-7/12 mx-auto py-[5rem]">
      <h2 className="text-[40px] font-semibold font-secondary text-[#00173A] text-center">
        The Website Translation Process
      </h2>

      <div className="flex flex-wrap gap-y-10 justify-between pt-[5rem]">
        <img src={Picture} alt="" className="sm:ml-[6rem] sm:block hidden"/>
        <div className="sm:mr-[4rem]">
          <ul className="flex flex-col gap-y-7">
            <li className="flex items-center gap-x-5">
              <div className="rounded-full max-w-max bg-orange px-5 py-3 font-bold text-white font-sans text-[20px]">
                1.
              </div>
              <span className="text-[22px] font-sans font-bold text-[#00173A]">
                UI Content Extraction
              </span>
            </li>
            <li className="flex items-center gap-x-5">
              <div className="rounded-full max-w-max bg-orange px-5 py-3 font-bold text-white font-sans text-[20px]">
                2.
              </div>
              <span className="text-[22px] font-sans font-bold text-[#00173A]">
                Linguistic and Functionality Quality Check
              </span>
            </li>
            <li className="flex items-center gap-x-5">
              <div className="rounded-full max-w-max bg-orange px-5 py-3 font-bold text-white font-sans text-[20px]">
                3.
              </div>
              <span className="text-[22px] font-sans font-bold text-[#00173A]">
                Project Management
              </span>
            </li>
            <li className="flex items-center gap-x-5">
              <div className="rounded-full max-w-max bg-orange px-5 py-3 font-bold text-white font-sans text-[20px]">
                4.
              </div>
              <span className="text-[22px] font-sans font-bold text-[#00173A]">
                Source Validation
              </span>
            </li>
            <li className="flex items-center gap-x-5">
              <div className="rounded-full max-w-max bg-orange px-5 py-3 font-bold text-white font-sans text-[20px]">
                5.
              </div>
              <span className="text-[22px] font-sans font-bold text-[#00173A]">
                QA and Linguistic QA
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NewCompOne;
