import WebsiteTranslation from 'components/homepage/websiteTranslation'
import Layout from 'components/layout'
import React from 'react'

const index = () => {
  return (
    <Layout>
        <WebsiteTranslation/>
    </Layout>
  )
}

export default index