import React from "react";
import second from "assets/ads-assets/98.svg";

const CarTwo = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={second} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            98% Customer Satisfaction Rate{" "}
          </h3>
          <p className="text-[15px] font-sans text-[#00173A] leading-loose pt-8">
            Tomedes’ exceptional reputation is built
            <br /> on years of exceptional services and <br /> high-quality
            solutions for all types of <br /> corporate translation needs around
            the <br />
            world.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarTwo;
