import React from "react";
import first from 'assets/ads-assets/customer.svg';


const CarOne = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={first} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            95,000 Business Customers
          </h3>
          <p className="text-[15px] font-sans text-[#00173A] leading-loose pt-8">
            Tomedes is delighted to count over 95,000 <br /> businesses worldwide among
            our clients. <br /> Our years of dedication and commitment to <br /> every project
            has led us to work hand-in- <br /> hand with Fortune 500 companies as <br /> well
            as small-to-medium businesses <br /> all around the  globe.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarOne;
