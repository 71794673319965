import React from "react";
// import LogoSvg from "assets/svg/logo";
import BBC from "assets/images/new-homepage-assets/desktop/bbc_logo.svg";

const Card = () => {
  return (
    <div
      className="right bg-white pl-[26px] sm:pl-10 p-10 sm:w-[409px] relative sm:mt-0 mt-8"
      // style="box-shadow: 0px 6px 10px #00000029; border-radius: 22px;"
    >
      <p className="font-bold text-[18px] font-sans">
        "Speedy return & <br /> reasonably priced"
      </p>
      <p className="text-[#00173A] font-sans leading-relaxed pt-5">
        A translation company well equipped with <br /> knowledge of hundreds of
        different <br /> languages - speedy return & reasonably <br /> priced.
      </p>

      <div className="pt-24">
        <img src={BBC} alt="" />
        <p className="text-[18px] font-bold font-sans pt-3">Katherine Lindsay</p>
      </div>
    </div>
  );
};

export default Card;
