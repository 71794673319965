import first from 'assets/ads-assets/customer.svg';
import second from 'assets/ads-assets/98.svg';
import third from 'assets/ads-assets/ISO.svg';
import fourth from 'assets/ads-assets/fast.svg';


export const Data = [
  {
    picture: first,
    head: "95,000 ",
    head2: "Business Customers",
    content:
      "Tomedes is delighted to count over 95,000 businesses worldwide among our clients. Our years of dedication and commitment to every project has led us to work hand-in-hand with Fortune 500 companies as well as small-to-medium businesses all around the globe.",
  },
  {
    picture: second,
    head: "98% Customer",
    head2: "Satisfaction Rate",
    content:
      "Tomedes’ exceptional reputation is built on years of exceptional services and high-quality solutions for all types of corporate translation needs around the world.",
  },
  {
    picture: third,
    head: "ISO Certified ",
    head2: "Providing High Quality",
    content:
      "As an ISO-certified translation provider, you can be sure that your project is of the highest level of quality assurance and care. At Tomedes you have 3 possibilities: ISO 17100:2015 for translation quality, ISO 9001:2015 for quality management systems, and ISO 18587:2017 for machine translation post editing.",
  },
  {
    picture: fourth,
    head: "Fast ",
    head2: "Delivery",
    content:
      "Do you have an urgent project? No worries! Tomedes guarantees fast, dependable delivery for translations of any size or complexity. Just sit back, relax, and let us do the work.",
  },
];

 export const NewData = [
  {
    picture: first,
    head2: "Expand Your Reach",
    content:
      "We support over 120 languages and 950+ language pairs along with all the adjustments to web pages required for a comprehensive localization effort.",
  },
  {
    picture: second,
    head2: "Improve UX",
    content:
      "Tomedes allows users to have a better experience in their desired languages by fully optimizing your website to be a multilingual one.",
  },
  {
    picture: third,
    head2: "Generate more leads",
    content:
      "Research shows that 65% of non-native English speakers prefer content in their native tongue---and the more they like your multilingual content, the more likely you are to generate leads.",
  },
 
];
