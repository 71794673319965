import React from "react";
import Dummy from "assets/fff.png";
import AdContact from "./AdContact";
import first from "assets/ads-assets/guarantee.svg";
import second from "assets/ads-assets/support.svg";
import third from "assets/ads-assets/team.svg";
import { Link } from "gatsby";

const FourthAdComp = ({ handleTop }) => {
  return (
    <div className="bg-[#012458] py-[6rem]">
      <div className="w-full sm:w-9/12 2xl:w-7/12 px-4 mx-auto">
        <h2 className="text-[40px] font-secondary text-center font-medium text-white">
          Why Should You Choose Our <br /> Comprehensive Website Solutions{" "}
        </h2>

        <p className="sm:w-[835px] text-[18px] font-sans text-center text-white mx-auto py-8">
          Tomedes website translation services are top-tier in terms of
          translator’s coverage, training, and certification, and who are
          knowledgeable in over 120 languages, with a one-year quality
          guarantee.
        </p>

        <div className="flex flex-wrap justify-around mx-auto pt-[55px] sm:pt-[6rem] items-center">
          <img src={first} alt="" className="sm:pb-0 pb-4 sm:w-auto w-[100px]" />
          <div className="w-[425px]">
            <h3 className="text-white text-[30px] text-center sm:text-left font-secondary">
              Scalable Website <br /> Translation Services
            </h3>
            <p className="text-[18px]  pt-6 text-white text-center sm:text-left">
              Tomedes specializes in providing website translation solutions
              that are easily scalable for our clients. In the context of
              localization, this means that you will be able to rapidly add
              additional language translations to further expand the reach of
              your site. For clients who prefer machine translation post
              editing, large volumes of data and documents can be translated for
              your website.
            </p>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col-reverse flex-wrap justify-around mx-auto pt-[55px] sm:pt-[8rem] items-center">
          <div className="sm:w-[425px]">
            <h3 className="text-white text-[30px] text-center sm:text-left font-secondary">
              Agile Delivery
            </h3>
            <p className="text-[18px]  pt-6 text-white text-center sm:text-left">
              With our rapid delivery, Tomedes facilitates harmonious
              cooperation with our partners throughout the website translation
              and localization effort. Our agile work processes are streamlined
              to accommodate our clients. Our client satisfaction rate of 97%
              proves that the client is at the heart of our philosophy.
            </p>
          </div>
          <img src={second} alt="" className="sm:pb-0 pb-4 sm:w-auto w-[100px]" />
        </div>
        <div className="flex flex-wrap justify-around mx-auto pt-[55px] sm:pt-[8rem] items-center">
          <img src={third} alt="" className="sm:pb-0 pb-4 sm:w-auto w-[100px]" />
          <div className="w-[425px]">
            <h3 className="text-white text-[30px] text-center sm:text-left font-secondary">
              24/7 Support
            </h3>
            <p className="text-[18px] pt-6 text-white text-center sm:text-left">
              Tomedes prides itself on round-the-clock support for our clients
              in website translation services and the overall localization
              process. Clients are each assigned a dedicated account team to
              accompany them throughout the effort, coordinating technical and
              linguistic teams.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-9/12 mx-auto pt-[8rem]">
      <div className="w-full  bg-GlobalContact bg-cover bg-no-repeat mx-auto py-7 px-8 md:px-12 text-white">
      <div className=" flex flex-col lg:flex-row md:items-center justify-between">
        <div className="text-[40px] w-full lg:w-[60%]">
          <h2 className="font-bold font-secondary sm:text-left text-center"> Looking for a <br /> Website Translator? </h2>
        </div>
        <div className="my-8 md:my-0 w-full pt-4 lg:pt-0 lg:w-[35%] flex justify-center xl:justify-start mx-auto">
          <Link
            // to="/contactus.php"
            onClickCapture={handleTop}
            className="font-opensans font-bold text-[20px] text-center uppercase rounded-full bg-[#000C27] text-white cursor-pointer  py-6 w-[300px] hover:bg-[#012A68]"
          >
            GET A FREE QUOTE NOW
          </Link>
        </div>
      </div>
    </div>
      </div>
    </div>
  );
};

export default FourthAdComp;
