import React from "react";
import third from 'assets/ads-assets/ISO.svg';


const CarThree = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={third} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
          ISO Certified <br /> Providing High Quality          </h3>
          <p className="text-[15px] font-sans text-[#00173A] leading-loose pt-8">
          As an ISO-certified translation provider,<br />  you can be sure that your project is of <br /> the highest level of quality assurance and care. <br /> At Tomedes you have 3 possibilities: <br /> ISO 17100:2015 for translation quality, <br /> ISO 9001:2015 for quality management systems,<br />and ISO 18587:2017 for machine translation <br /> post editing.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarThree;
