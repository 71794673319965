import React from "react";
import Ashrena from "assets/images/1.png";
import BBC from "assets/images/new-homepage-assets/desktop/bbc_logo.svg";

const Card2 = () => {
  return (
    <div
      className="right bg-white pl-[26px] sm:pl-10 p-10 sm:w-[409px] relative sm:mt-0 mt-8"
      // style="box-shadow: 0px 6px 10px #00000029; border-radius: 22px;"
    >
      <p className="font-bold text-[18px] font-sans">
        "Tomedes has been nothing but kind, <br /> knowledgeable and efficient!"{" "}
      </p>
      <p className="text-[#00173A] font-sans leading-relaxed pt-5">
        Tomedes has been nothing but kind, <br /> knowledgeable and
        efficient!...Everything <br /> was seamless! I trusted Tomedes so much{" "}
        <br /> they’re now working...with an even bigger <br /> project:
        interpreting a multi-language Zoom <br /> meeting!
      </p>

      <div className="pt-24">
        <img src={Ashrena} alt="" />
        <p className="text-[18px] font-bold font-sans pt-3">
        Ashrena Ali
        </p>
      </div>
    </div>
  );
};

export default Card2;
