import React from "react";
import Picture from "assets/ads-assets/img2.webp";

const SixthAdComp = ({ handleTop }) => {
  return (
    <div className="bg-[rgb(245,252,255)] pt-8 sm:pt-[10rem]">
      <div className=" w-full px-4 sm:px-0 sm:w-10/12 2xl:w-9/12 mx-auto">
        <div className="flex flex-wrap mx-auto justify-around items-center">
          <img src={Picture} alt="" />
          <div className="w-full sm:w-[494px] pt-8 sm:pt-0">
            <h2 className="text-[40px] sm:text-left text-center font-semibold  font-secondary pb-6 text-[#00173A]">
              Professional Website Translation Services{" "}
            </h2>
            <p className=" sm:text-left text-center font-sans text-[18px] leading-loose">
              Website translation consists of fine-tuning different aspects of
              the website based on international standards of multilingual
              websites, SEO, and localized market research. At Tomedes, our
              website translators adapt content into any of over 120 languages
              and 950+ language pairs, converting the formatting, measurement
              and currency units along with the text strings. Our website
              translation services have the capability of turning any website
              into a multilingual one.
            </p>
          </div>
        </div>
        <p className="text-[#00173A] text-[28px] w-full font-secondary mx-auto py-[7rem] text-center">
          Translate your web content in over 120 languages in a way search
          engines will love, enabling your pages to get global traffic.
        </p>
      </div>
      <div className="bg-[#012458] flex flex-wrap mx-auto justify-center min-h-[169px] items-center sm:py-0 py-6 gap-x-6">
        <p className="text-[40px] text-white font-secondary sm:text-left text-center pb-3 sm:pb-0 sm:mr-[7rem]">
          Want To Know More?
        </p>
        <button
          onClickCapture={handleTop}
          className="font-bold font-sans text-[18px] rounded-full bg-orange hover:bg-[#FF9848] px-14 py-4 text-white"
        >
          START NOW
        </button>
      </div>
    </div>
  );
};

export default SixthAdComp;
