import React from "react";
import FirstAdComp from "./firstAdComp";
import SecondAdComp from "./secondAdComp";
import { Data, NewData } from "./config";
import ThireAdComp from "./thireAdComp";
import FourthComp from "components/reusableComponents/transcript-translation-services/fourthComp";
import FourthAdComp from "./fourthAdComp";
import FifthAdComp from "./fifthAdComp";
import SixthAdComp from "./sixthAdComp";
import SeventhAdComp from "./seventhAdComp";
import NewCompOne from "./newCompOne";

const WebsiteTranslation = () => {
  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <FirstAdComp />
      <SecondAdComp data={Data} />
      <ThireAdComp handleTop={handleTop} />
      <FourthAdComp handleTop={handleTop} />
      <FifthAdComp data={NewData} />
      <NewCompOne/>
      <SixthAdComp handleTop={handleTop} />
      <SeventhAdComp handleTop={handleTop} content midComp lastSection CTA />
    </>
  );
};

export default WebsiteTranslation;
