import React from "react";
import Stars from "assets/ads-assets/stars.svg";
import AdsCarousel from "./carosule";
import GrowthCarousel from "./growthCarousel";

const SecondAdComp = ({ data }) => {
  return (
    <div className="bg-white py-16">
      <div className="flex flex-col ">
        <div className="flex flex-wrap mx-auto justify-between w-full sm:w-10/12 px-2 2xl:w-7/12">
          <div className="left w-full sm:w-[485px]">
            <h2 className="sm:text-[40px] text-[#00173A] text-[35px] font-bold font-secondary sm:text-left text-center">
              {" "}
              <span className="text-orange">4.9 out of 5</span> <br /> Aggregate
              Rating
            </h2>
            <p className="text-[18px] sm:text-[25px] font-secondary text-[#00173A] pt-4 sm:text-left text-center">
              (Out of 1798 Verified Reviews Online)
            </p>
            <img src={Stars} alt="" className="py-6" />
            <p className="text-[18px] text-[#00173A] font-sans ">
              Tomedes takes pride in 95,000+ satisfied customers, around the
              globe, a number that includes several Fortune 500 companies. We
              have a 4.9 out of 5 aggregate star rating from 1,798 online
              reviews.
            </p>
          </div>

          <AdsCarousel />
          {/* -------Carousel------- */}
        </div>
        <div className="bottoM w-full sm:w-10/12 lg:w-11/12 sm:flex flex-col justify-center mx-auto pt-[10rem] 2xl:w-7/12 hidden">
          <h2 className="text-[26px] sm:text-[40px] font-secondary text-[#00173A] flex justify-center mx-auto text-center w-9/12">
            What Makes Tomedes Your Growth Partner?
          </h2>
          <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
            {data
              ? data.map(({ index, head, content, picture, head2 }) => (
                  <div className="text-center" key={index}>
                    <img src={picture} alt="" className="mx-auto pb-2" />
                    <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
                      {head} <br /> {head2}
                    </h3>
                    <p className="text-[15px] font-sans text-[#00173A] leading-loose pt-8">
                      {content}
                    </p>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="sm:hidden pt-[10rem] flex">
          <GrowthCarousel />
        </div>
      </div>
    </div>
  );
};

export default SecondAdComp;
